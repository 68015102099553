import { getSignature } from "@/api/user";

let accessid = "";
let policyBase64 = "";
let signature = "";
let callbackbody = "";
let key = "";
// let expire = 0
let host = "";
let g_object_name = "";
// let now = Date.parse(new Date()) / 1000

// 生成随机字符串
function random_string(len) {
  len = len || 32;
  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxPos = chars.length;
  var pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
// 获取用户上传原始文件名
function get_file_name(filename) {
  let pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
}


function split_file_ame(filename) {
  return filename.substring(0,filename.indexOf("."));
}

function getNowFormatDate () { //获取当前时间
  let date = new Date();
  let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; //获取月,如果小于10,前面补个0
  let strDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取日,如果小于10,前面补个0
  let strHours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取小时,如果小于10,前面补个0
  let strMinutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //获取分,如果小于10,前面补个0
  let strSeconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //获取秒,如果小于10,前面补个0
  let currentdate = date.getFullYear() + month +  strDate + strHours + strMinutes + strSeconds; //拼接一下
  return currentdate; //返回
}


//  把随机生成的字符串拼接在原始上传文件名后面生成新的唯一文件名
function set_file_name(filename) {
  let suffix = get_file_name(filename);
  console.log(random_string(10))
  // g_object_name = key + random_string(10) + suffix;
  let name = split_file_ame(filename)
  let time = getNowFormatDate(); //打印输出:2020.04.02 12:34:56
  g_object_name = key + name +'_'+ time + suffix;
  return "";
}
// 获取后端返回的签名信息，生成oss参数
function oss(filename = null, url) {
  // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下， 3 s 作为缓冲。
  // now = Date.parse(new Date()) / 1000
  // if (expire < now + 3) {
  // 调用后端服务器接口获取签名信息，利用axios返回promise，可以链式调用
  return getSignature(url).then((res) => {
    let data = res.data;
    /* 返回的签名策略信息包含:
      {
        accessid: "LTAI*******UPPr", // 用户请求的accessid
        callback: "eyJjYWxs************H0ifQ==", // 回调
        dir: "test/file-dir/", // 上传文件的存储位置
        expire: "1557974779", // 上传策略Policy失效时间
        host: "http://xxxxxxxxx.com", // 上传文件服务器地址
        policy: "eyJleHBp***********6/EMG7U=" ,// 用户表单上传的策略（Policy)
        signature: "JumJy*****k6/EMG7U=" // 签名信息
      }
      */
    policyBase64 = data["policy"];
    accessid = data["accessid"];
    signature = data["signature"];
    // expire = parseInt(data['expire'])
    callbackbody = data["callback"];
    host = data["host"];
    key = data["dir"];

    if (filename != null) {
      set_file_name(filename);
    }
    // 返回表单上传需要的参数信息
    return {
      host: host,
      key: g_object_name,
      policy: policyBase64,
      OSSAccessKeyId: accessid,
      success_action_status: "200", // 让服务端返回200,不然，默认会返回204
      callback: callbackbody,
      signature: signature,
    };
  });
  // }
}

export { oss };
