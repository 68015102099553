<template>
  <div class="smt_flow">
    <topnav ref="navs" active="smt"></topnav>
    <topmenu active="smt"></topmenu>

    <div class="public_width">
        <ul class="step_box d-flex">
          <li class="step" :class="step >= 1 ? 'active':''">
            <span class="step_num">1</span>
            <div class="step_txt">
              <p class="title">物料信息</p>
              <p class="txt">填写需要生产的产品加工信息</p>
            </div>
          </li>
          <li class="line" :class="step >= 2 ? 'active':''"></li>
          <li class="step" :class="step >= 2 ? 'active':''">
            <span class="step_num">2</span>
            <div class="step_txt">
              <p class="title">上传文件</p>
              <p class="txt">上传相关生产文件</p>
            </div>
          </li>
          <li class="line"  :class="step >= 3 ? 'active':''"></li>
          <li class="step" :class="step >= 3 ? 'active':''">
            <span class="step_num">3</span>
            <div class="step_txt">
              <p class="title">确认提交</p>
              <p class="txt">确认订单的信息并提交</p>
            </div>
          </li>
        </ul>
        <!-- 第一步 -->
        <div class="d-flex" v-if="step == 1">
          <div class="flow_left">    
            <el-form ref="form_info" :model="form" :rules="rules" label-width="180px">
              <div class="log_title">基础信息</div>
              <div class="log_box">
                <el-form-item label="单板生产数量：" prop="veneer_num">
                  <el-input v-model.number="form.veneer_num" placeholder="请输入" @input="number($event,'veneer_num')">
                    <template #append>pcs</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="PCB单板尺寸：" prop="veneer_length">
                  <el-input class="short" v-model="form.veneer_length" placeholder="长"  @input="part($event,'veneer_length')">
                    <template #append>cm</template>
                  </el-input>
                  <span class="symbol">x</span>
                  <el-input class="short" v-model="form.veneer_width" placeholder="宽"  @input="part($event,'veneer_width')">
                    <template #append>cm</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="拼版数量：">
                  <el-input v-model.number="form.panel_num" placeholder="请输入" @input="number($event,'panel_num')">
                    <template #append>pcs</template>
                  </el-input>
                </el-form-item>
              </div>

              <div class="log_title">SMT贴片</div>
              <div class="log_box">
                <el-form-item label="单/双面：" required>
                  <el-radio-group v-model="form.smt_cover_type" @change="change_smt($event,true)">
                    <el-radio-button label="单面"></el-radio-button>
                    <el-radio-button label="双面"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="贴片物料种类：">
                  <el-input v-model.number="form.smt_material_num" placeholder="BOM表中的贴片物料型号数" @input="number($event,'smt_material_num')"></el-input>
                </el-form-item>
                <el-form-item label="贴片焊盘数：">
                  <el-input v-model.number="form.smt_welding_num" placeholder="BOM表中的所有贴片物料的焊盘数" @input="number($event,'smt_welding_num')"></el-input>
                </el-form-item>
              </div>

              <div class="log_title">DIP插件</div>
              <div class="log_box">
                <el-form-item label="焊接插件料：" required>
                  <el-radio-group v-model="form.dip_plug_need" @change="change_dip($event,true)">
                    <el-radio-button label="是"></el-radio-button>
                    <el-radio-button label="否"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="插件物料种类：">
                  <el-input v-model.number="form.dip_plug_material_num" placeholder="BOM表中的插件物料型号数" @input="number($event,'dip_plug_material_num')" :disabled="form.dip_plug_need =='否'"></el-input>
                </el-form-item>
                <el-form-item label="插件焊盘数："> 
                  <el-input v-model.number="form.dip_plug_welding_num" placeholder="BOM表中的所有插件物料的焊盘数" @input="number($event,'dip_plug_welding_num')" :disabled="form.dip_plug_need =='否'"></el-input>
                </el-form-item>
              </div>

              <div class="log_title">特殊工艺</div>
              <div class="log_box">
                <el-form-item label="需要拆板：" required>
                  <el-radio-group v-model="form.special_patch" @change="change_patch($event,true)">
                    <el-radio-button label="是"></el-radio-button>
                    <el-radio-button label="否"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="烧录程序：" required>
                  <el-radio-group v-model="form.special_program" @change="change_program($event,true)">
                    <el-radio-button label="是"></el-radio-button>
                    <el-radio-button label="否"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="X-RAY元件数：">
                  <el-input v-model.number="form.special_element_num" placeholder="单板需X-RAY检测的元件数量" @input="number($event,'special_element_num')" :disabled="form.special_patch =='否'"></el-input>
                </el-form-item>
                <el-form-item label="X-RAY单板数：">
                  <el-input v-model.number="form.special_veneer_num" placeholder="需X-RAY检测的单板数量" @input="number($event,'special_veneer_num')" :disabled="form.special_patch =='否'"></el-input>
                </el-form-item>
              </div>

              <div class="log_title">功能测试 <span class="down">下单后以工厂评估时进行收费</span></div>
              <div class="log_box">
                <el-form-item label="是否需要功能测试：" required>
                  <el-radio-group v-model="form.function_test">
                    <el-radio-button label="是"></el-radio-button>
                    <el-radio-button label="否"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否需要通电老化测试：" required >
                  <el-radio-group v-model="form.old_test">
                    <el-radio-button label="是"></el-radio-button>
                    <el-radio-button label="否"></el-radio-button> 
                  </el-radio-group>
                </el-form-item>
              </div>

            </el-form>
          </div>
          <div class="flow_right">
            <div class="process_title">参数详情</div>
            <table class="process_table">
              <tr><td class="bg">单板生产数量</td><td>{{form.veneer_num}}</td><td class="bg">PCB单板尺寸</td><td><span v-if="form.veneer_length || form.veneer_width">{{form.veneer_length || 0}}cm x {{form.veneer_width || 0}}cm</span></td></tr>
              <tr><td class="bg">烧录程序</td><td>{{form.special_program}}</td><td class="bg">拼版数量</td><td>{{form.panel_num}}</td></tr>
              <tr><td class="bg">SMT贴片</td><td>{{form.smt_cover_type}}</td><td class="bg">功能测试</td><td>{{form.function_test}}</td></tr>
              <tr><td class="bg">焊接插件料</td><td>{{form.dip_plug_need}}</td><td class="bg">通电老化测试</td><td>{{form.old_test}}</td></tr>
              <tr><td class="bg">需要拆板</td><td>{{form.special_patch}}</td><td class="bg"></td><td></td></tr>
            </table>
            <div class="process_title">报价</div>
            <table class="process_table ">
              <tr><td class="bg">SMT贴片</td><td class="orange">￥{{form.smt_patch_money}}</td><td class="bg">DIP插件</td><td class="orange">￥{{form.dip_plug_money}}</td></tr>
              <tr><td class="bg">特殊工艺</td><td class="orange">￥{{form.special_technique_money}}</td><td class="bg"></td><td></td></tr>
            </table>
            <div class="process_title">包装物流</div>
            <table class="process_table">
              <tr><td class="bg">包装物流费用</td><td>￥{{form.packaging_money}}</td><td class="bg">标准包装辅材</td><td>气泡棉</td></tr>
              <tr><td colspan="4" class="express">默认按 江苏南京 顺丰寄付计费，最终以实际配送方式为准</td></tr>
            </table>
            
            <div class="process_price">
              <span class="price_title">预计价：</span>
              <span class="price_money">￥{{form.forecast_money_base}}</span>
            </div>
            <div class="process_tips">
              <p class="price_warm">温馨提示：</p>
              <p class="price_tip">1.因材料及工艺不同，具体价格以下单后客服的报价为准</p>
              <p class="price_tip">2.此处的预计金额仅加工费用，不包含物料费用</p>
            </div>
            <el-button class="process_btn" @click="to_second()">下一步</el-button>
          </div>
        </div>


        <!-- 第二步 -->
        <div class="d-flex" v-if="step == 2">
          <div class="flow_file">
            <div class="file_title">相关文件</div>
            <el-form ref="form_up" :model="form_file" :rules="rules_file" label-width="120px">
              <el-form-item label="PCB定制：" prop="pcb_custom_file">
                <el-radio-group v-model="form_file.pcb_custom">
                  <el-radio-button label="平台代采"></el-radio-button>
                  <el-radio-button label="自己提供PCB"></el-radio-button> 
                </el-radio-group>
                <div class="up_box">
                  <el-checkbox-group v-show="false" v-model="form_file.pcb_custom_file"></el-checkbox-group>
                  <el-upload
                    :action="pcb_host"
                    :data="pcb_data"
                    :file-list="pcb_list"
                    :before-upload="pcb_before"
                    :on-success="pcb_success"
                    accept=".rar,.zip"
                    :show-file-list="false"
                    limit="1"
                    v-if="pcb_show"
                  >
                    <div class="up_btn">
                      <i class="iconfont icon-s-scwj"></i>上传PCB文件
                    </div>
                  </el-upload>
                  <div class="file_box" v-else>
                    <div class="up_file"><i class="file-icon t-icon t-icon-E-gz"></i> <a :href="form_file.pcb_custom_file" :download="form_file.pcb_custom_file_name">{{form_file.pcb_custom_file_name}}</a> <i class="close-icon t-icon t-icon-s-c" @click="pcb_remove"></i></div>
                  </div>
                  <p class="up_tip">
                    支持文件格式：.rar .zip，单个文件不能超过5M
                  </p>
                </div>
              </el-form-item>

              <el-form-item label="BOM物料采购：" prop="bom_custom_file">
                <el-radio-group v-model="form_file.bom_custom">
                  <el-radio-button label="平台代采"></el-radio-button>
                  <el-radio-button label="自己提供物料"></el-radio-button> 
                </el-radio-group>
                <div class="up_box">
                  <el-checkbox-group v-show="false" v-model="form_file.bom_custom_file"></el-checkbox-group>
                  <el-upload
                    :action="bom_host"
                    :data="bom_data"
                    :file-list="bom_list"
                    :before-upload="bom_before"
                    :on-success="bom_success"
                    accept=".xls,.xlsx,.csv "
                    :show-file-list="false"
                    limit="1"
                    v-if="bom_show"
                  >
                    <div class="up_btn">
                      <i class="iconfont icon-s-scwj"></i>上传BOM文件
                    </div>
                  </el-upload>
                  <div class="file_box" v-else>
                    <div class="up_file"><i class="file-icon t-icon t-icon-E-xlsx"></i> <a :href="form_file.bom_custom_file" :download="form_file.bom_custom_file_name">{{form_file.bom_custom_file_name}}</a> <i class="close-icon t-icon t-icon-s-c" @click="bom_remove"></i></div>
                  </div>
                  <p class="up_tip">
                    支持文件格式：xls、xlsx、csv ，单个文件不能超过5M
                  </p>
                </div>
              </el-form-item>

              <el-form-item label="SMT文件：" prop="smt_file">
                <div class="up_box up_box_none">
                  <el-checkbox-group v-show="false" v-model="form_file.smt_file"></el-checkbox-group>
                  <el-upload
                    :action="smt_host"
                    :data="smt_data"
                    :file-list="smt_list"
                    :before-upload="smt_before"
                    :on-success="smt_success"
                    accept=".xls,.xlsx,.csv "
                    :show-file-list="false"
                    limit="1"
                    v-if="smt_show"
                  >
                    <div class="up_btn">
                      <i class="iconfont icon-s-scwj"></i>上传SMT文件
                    </div>
                  </el-upload>
                  <div class="file_box" v-else>
                    <div class="up_file"><i class="file-icon t-icon t-icon-E-xlsx"></i> <a :href="form_file.smt_file" :download="form_file.smt_file_name">{{form_file.smt_file_name}}</a> <i class="close-icon t-icon t-icon-s-c" @click="smt_remove"></i></div>
                  </div>
                  <p class="up_tip">
                    支持文件格式：xls、xlsx、csv ，单个文件不能超过5M
                  </p>
                </div>
              </el-form-item>

              <el-form-item label="丝印图：" prop="print_file">
                <div class="up_box up_box_none">
                  <el-checkbox-group v-show="false" v-model="form_file.print_file"></el-checkbox-group>
                  <el-upload
                    :action="print_host"
                    :data="print_data"
                    :file-list="print_list"
                    :before-upload="print_before"
                    :on-success="print_success"
                    accept=".pdf "
                    :show-file-list="false"
                    limit="1"
                    v-if="print_show"
                  >
                    <div class="up_btn">
                      <i class="iconfont icon-s-scwj"></i>上传文件
                    </div>
                  </el-upload>
                  <div class="file_box" v-else>
                    <div class="up_file"><i class="file-icon t-icon t-icon-E-pdf"></i> <a :href="form_file.print_file" :download="form_file.print_file_name" target="_blank">{{form_file.print_file_name}}</a> <i class="close-icon t-icon t-icon-s-c" @click="print_remove"></i></div>
                  </div>
                  <p class="up_tip">
                    支持文件格式：.pdf ，单个文件不能超过5M
                  </p>
                </div>
              </el-form-item>

              <el-form-item label="钢网：" prop="stencil" required>
                <el-radio-group v-model="form_file.stencil">
                  <el-radio-button label="平台定制"></el-radio-button>
                  <el-radio-button label="客户提供"></el-radio-button> 
                </el-radio-group>
              </el-form-item>


              <el-form-item label="订单备注：">
                <el-input v-model="form_file.note" maxlength="300" placeholder="可对上传内容进行补充说明，以免出现生产问题" show-word-limit type="textarea" resize="none"/>
                <div class="up_tip">交期：正常5-14天，若需加急请在备注中详细说明</div>
              </el-form-item>

              <el-form-item label="联系方式：">
                <div class="tel"> 
                  <el-form-item label="联系人：" >
                    <el-input v-model="form_file.connect_person" placeholder="请输入联系人姓名" />
                  </el-form-item>
                  <el-form-item label="手机号：" prop="connect_phone">
                    <el-input v-model="form_file.connect_phone" placeholder="请输入联系方式" />
                  </el-form-item>
                  <el-form-item label="邮箱：" prop="connect_email">
                    <el-input v-model="form_file.connect_email" placeholder="请输入邮箱" />
                  </el-form-item>
                </div>
                <div class="up_tip">方便客服即使沟通相关事宜</div>
              </el-form-item>

              <div class="file_btn">
                <el-button class="btn prev" @click="to_first()">上一步</el-button>
                <el-button class="btn next" @click="to_third()">下一步</el-button>
              </div>
            </el-form>

          </div>
        </div>


        <!-- 第三步 -->
        <div class="flow_pay" v-if="step == 3">
          <h3 class="pay_title">订单确认</h3>
          <div class="settlement_main">
            <!-- 确认收货地址 -->
            <div class="com_box">
                <div class="com_title">确认收货地址<div class="trans_line"></div></div>
                <div class="box_main" :class="{ has_address_box: address_show }">
                    <!-- 没有地址时 -->
                    <el-form
                        :model="address_form"
                        :rules="address_rules"
                        ref="form_addr"
                        label-width="100px"
                        v-if="address_show === false"
                    >
                        <el-form-item label="收货人:" prop="name">
                            <el-input v-model="address_form.name"></el-input>
                        </el-form-item>

                        <el-form-item label="所在地区:" prop="district">
                            <el-cascader
                            v-model="address_form.district"
                            :options="options"
                            :props="{ expandTrigger: 'hover' }"
                            @change="cascaderChange"
                            ></el-cascader>
                        </el-form-item>

                        <el-form-item label="详细地址:" prop="address">
                            <el-input v-model="address_form.address"></el-input>
                        </el-form-item>

                        <el-form-item label="手机/固话:" prop="tel">
                            <el-input v-model="address_form.tel"></el-input>
                        </el-form-item>

                        <el-form-item label="邮政编码:">
                            <el-input v-model="address_form.postcode"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="sub_address_form()">保存</el-button>
                        </el-form-item>
                    </el-form>

                    <!-- 有地址 循环展示 -->
                    <div v-if="address_show === true">
                        <div
                            class="item_address"
                            :class="{ active_address: active_index == index }"
                            v-for="(item, index) in addressList"
                            :key="index"
                            @click="toggle_address(index)"
                        >
                            <div class="left_txt">寄送到</div>
                            <div class="readio_box">
                                <div class="com_radio"></div>
                                <div class="active_radio"></div>
                            </div>

                            <div class="address_box">
                                <span class="district_txt"> {{ item.district.replace(/[/]/g,"")}}</span>
                                <span class="address_txt"> {{ item.address }}</span>
                                （ <span class="name_txt"> {{ item.name }} </span>）收
                                <span class="tel_txt"> {{ item.tel }}</span>
                            </div>

                            <div class="default_address" v-if="item.default == '是'">默认地址</div>
                        </div>

                        <div class="add_address_btn" @click="changeAddress(true)">
                            新增收货地址
                        </div>
                    </div>
                </div>
            </div>

            <!-- 确认加工信息 -->
            <div class="com_box">
                <div class="com_title">确认加工信息<div class="trans_line"></div></div>
                <div class="box_main" >
                  <div class="d-flex">
                    <div class="base_left">
                      <div class="base_title">加工信息</div>
                      <table class="base_table">
                        <tr>
                          <td class="bg">基础信息</td>
                          <td><span class="title">单板生产数量:</span><span class="val">{{ form.veneer_num }}</span></td>
                          <td><span class="title">拼版数量:</span><span class="val">{{ form.panel_num }}</span></td>
                          <td><span class="title">PCB单板尺寸:</span><span class="val">{{form.veneer_length || 0}}cm x {{form.veneer_width || 0}}cm</span></td>
                        </tr>
                        <tr>
                          <td class="bg">SMT贴片</td>
                          <td><span class="title">单双面:</span><span class="val">{{ form.smt_cover_type }}</span></td>
                          <td><span class="title">贴片物料种类:</span><span class="val">{{ form.smt_material_num }}</span></td>
                          <td><span class="title">贴片焊盘数:</span><span class="val">{{form.smt_welding_num }}</span></td>
                        </tr>
                        <tr>
                          <td class="bg">DIP插件</td>
                          <td><span class="title">焊接插件料:</span><span class="val">{{ form.dip_plug_need }}</span></td>
                          <td><span class="title">插件物料种类:</span><span class="val">{{ form.dip_plug_material_num }}</span></td>
                          <td><span class="title">插件焊盘数:</span><span class="val">{{form.dip_plug_welding_num }}</span></td>
                        </tr>
                        <tr>
                          <td class="bg">特殊工艺</td>
                          <td>
                            <p class="line"><span class="title">需要拆板:</span><span class="val">{{ form.special_patch }}</span></p>
                            <p class="line"><span class="title">烧录程序:</span><span class="val">{{ form.special_program }}</span></p>
                          </td>
                          <td colspan="2">
                            <p class="line num"><span class="title">X-RAY元件数:</span><span class="val">{{ form.special_element_num }}</span></p>
                            <p class="line num"><span class="title">X-RAY单板数:</span><span class="val">{{ form.special_veneer_num }}</span></p>
                          </td>
                        </tr>
                        <tr>
                          <td class="bg">功能测试</td>
                          <td><span class="title">是否需要功能测试:</span><span class="val">{{ form.function_test }}</span></td>
                          <td colspan="2"><p class="num"><span class="title">是否需要通电老化测试:</span><span class="val">{{ form.old_test }}</span></p></td>
                        </tr>
                        <tr>
                          <td class="bg">包装物流</td>
                          <td><span class="title">标准包装：</span><span class="val">气泡棉</span></td>
                          <td colspan="2"></td>
                        </tr>
                      </table>
                    </div>
                    <div class="base_right">
                      <div class="base_title">相关文件</div>
                      <table class="base_table ">
                        <tr><td class="bg">PCB</td><td>{{form_file.pcb_custom}}</td><td><a :href="form_file.pcb_custom_file" :download="form_file.pcb_custom_file_name">{{form_file.pcb_custom_file_name}}</a></td></tr>
                        <tr><td class="bg">BOM物料</td><td>{{form_file.bom_custom}}</td><td><a :href="form_file.bom_custom_file" :download="form_file.bom_custom_file_name">{{form_file.bom_custom_file_name}}</a></td></tr>
                        <tr><td class="bg">SMT文件</td><td colspan="2"> <a :href="form_file.smt_file" :download="form_file.smt_file_name">{{form_file.smt_file_name}}</a></td></tr>
                        <tr><td class="bg">丝印图</td><td colspan="2"><a :href="form_file.print_file" :download="form_file.print_file_name" target="_blank">{{form_file.print_file_name}}</a></td></tr>
                        <tr><td class="bg">钢网</td><td colspan="2">{{form_file.stencil}}</td></tr>
                      </table>
                      <div class="day">预计交期:5~14天</div>
                    </div>
                  </div>
                  <p class="base_tip" v-if="form_file.note">*{{form_file.note}}</p>
                </div>
            </div>

            <!-- 发票信息 展示 -->
            <div class="com_box invoice_box" >
                <div class="com_title">开票信息<div class="trans_line"></div></div>

                <div class="box_main">
                    <div class="choice d-flex">
                        <span class="choice_title">开票类型:</span>
                        <el-radio-group class="d-flex" v-model="check_type" @change="change_type">
                            <el-radio :label="'不开票'">不开票</el-radio>
                            <el-radio :label="'电子发票'">电子发票</el-radio>
                            <el-radio :label="'增值税普票'">增值税普票</el-radio>
                            <el-radio :label="'增值税专票'">增值税专票</el-radio>
                        </el-radio-group>
                    </div>

                    <div class="form" v-if="check_type != '不开票'">
                        <div class="warm_prompt d-flex">
                            <div>温馨提示：</div>
                            <div>请确保所填开票信息与贵公司税务登记证一致。发票将于订单签收后第二个工作日开具（特殊情况无法受理除外，节假日顺延），并发送至邮箱。</div>
                        </div>

                        <div class="check_detail" >
                            <!-- 无发票 -->
                            <div v-if="has_invoice === false">
                                <el-form :model="invoiceInfo" ref="form_invoice" :rules="invoice_rules" label-width="100px"
                                >
                                    <el-form-item label="开票抬头:" prop="company">
                                        <el-input v-model="invoiceInfo.company"></el-input>
                                    </el-form-item>

                                    <el-form-item label="税号:" prop="tax_number">
                                        <el-input v-model="invoiceInfo.tax_number"></el-input>
                                    </el-form-item>

                                    <el-form-item label="单位地址:">
                                        <el-input v-model="invoiceInfo.address"></el-input>
                                    </el-form-item>

                                    <el-form-item label="电话号码:">
                                        <el-input v-model="invoiceInfo.tel"></el-input>
                                    </el-form-item>

                                    <el-form-item label="开户银行:">
                                        <el-input v-model="invoiceInfo.company_bank"></el-input>
                                    </el-form-item>

                                    <el-form-item label="银行账号:">
                                        <el-input v-model="invoiceInfo.bank_account"></el-input>
                                    </el-form-item>

                                    <el-form-item label="邮箱:" prop="email">
                                        <el-input v-model="invoiceInfo.email"></el-input>
                                    </el-form-item>

                                    <el-form-item class="confirm_btns">
                                        <el-button type="primary" @click="sub_invoice_form()">保存</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>

                            <!-- 有发票 -->
                            <div class="has_invoice" v-else-if="has_invoice === true">
                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">公司名称:</div>
                                    <p>{{ invoiceInfo.company }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">公司税号:</div>
                                    <p>{{ invoiceInfo.tax_number }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">单位地址:</div>
                                    <p>{{ invoiceInfo.address ? invoiceInfo.address : "/" }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">电话号码:</div>
                                    <p>{{ invoiceInfo.tel ? invoiceInfo.tel : "/" }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">开户银行:</div>
                                    <p>
                                        {{
                                        invoiceInfo.deposit_bank ? invoiceInfo.deposit_bank : "/"
                                        }}
                                    </p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">银行账号:</div>
                                    <p>
                                        {{
                                        invoiceInfo.bank_account ? invoiceInfo.bank_account : "/"
                                        }}
                                    </p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label">电子邮箱:</div>
                                    <p>{{ invoiceInfo.email }}</p>
                                </div>

                                <div class="invoice_form_view">
                                    <div for="username" class="col-form-label"></div>
                                    <div class="edit_btns d-flex">
                                        <div class="item_btn left_btn" @click="changeInvoice(true, 'edit')">修改</div>
                                        <div class="item_btn" @click="changeInvoice(true)">重填</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <!-- 商品订单信息金额展示 -->
            <div>
              <div class="bottom_money">
                  <div class="info_box">
                      <div>
                          SMT预估价（含税费）：
                      </div>
                      <div class="right_money">¥{{ form.forecast_money}}</div>
                  </div>

                  <div class="delivery_txt" v-if="addressList.length > 0">
                      收货地址：<span>
                      {{
                          addressList.length > 0
                          ? addressList[active_index].district.replace(/[/]/g,"")
                          : ""
                      }}
                      {{
                          addressList.length > 0
                          ? addressList[active_index].address
                          : ""
                      }}
                      </span>
                  </div>

                  <div class="delivery_txt">
                      收件人：<span>
                      {{
                          addressList.length > 0
                          ? addressList[active_index].name
                          : ""
                      }}
                      {{
                          addressList.length > 0
                          ? addressList[active_index].tel
                          : ""
                      }}
                      </span>
                  </div>

                  <div class="check_tip">订单价格会做审核，以最终审核的为准,不包含BOM费用</div>

                  <div class="info_wrap">
                    <div class="info_box deal_box d-flex">
                        <div class="deal"><input type="checkbox" v-model="check_deal" @click="change_deal(check_deal)"/></div>需要阅读并同意<span class="blue" @click="pactOpen()">《贴片协议》</span>
                    </div>
                  </div>
            </div>

              <!-- 提交 -->
              <div class="pay_btn">
                  <el-button class="btn btn-prev" @click="to_two()" >上一步</el-button>
                  <el-button class="btn btn-primary" :class="{ btn_sty: !check_deal }" @click="order()" :disabled="order_bol">提交</el-button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <foot />
    <toolbar />

     <el-dialog custom-class="modal_pact" v-model="pact_show" :before-close="pactClose"> 
        <template v-slot:title>
            <div class="dialog-title">贴片协议</div>
        </template>  
        <div class="dialog-content">
          <div>本协议是客户（以下简称“甲方”或“您”）与南京启诺信息技术有限公司（以下简称“启诺”或“乙方”或“加工方”）就SMT贴片&DIP PCBA加工服务等相关事宜共同缔结。本须知类同合同效力，请您务必审慎阅读、充分理解各条款内容并同意。当您下达订单视同同意此约定，即构成对双方有约束力的法律文件。</div>
          <div class="space">关于免责条款</div>
          <ul>
            <li>1.客供物料不齐，但客户要求上线生产的情况，我司不提供免费手工焊料服务；</li>
            <li>2.客供物料不良，如PCB断线、物料假货，物料封装不对等问题，我司不承担任何责任；</li>
            <li>3.若生产过程中出现异常，无法联系到客户相关人员导致异常无法确认的，我司有权自行处理异常情况，但不承担因异常处理导致的相关责任。</li>
            <li> 4.因客户提供的技术资料、生产文件、样机或样机图示导致的贴装及焊接错误，我司不承担任何责任;</li>
            <li> 5.因客户设计、客供物料损坏、物料与焊盘不匹配等非我司导致的问题，后续需要我司另行提供生产或售后维修的，我司仅提供有偿服务；非我司原因需要提供生产或售后维修引发的品质风险、物料报废、产品报废等，需要客户同意免责后，我司再安排相关作业。</li>
            <li> 6.我司不承担客供物料有效期超期或标识不清无法辨识、温湿度敏感元件未作真空包装防护受潮导致无铅回流焊接（230℃~260℃）物料损坏的相关责任；</li>
            <li>7.客户提供的插件物料需要能够承受150℃的波峰焊接预热温度，若不能满足上述条件导致的不良，我司不承担任何责任。</li>
            <li> 8.客户提供的贵重芯片需使用行业标准的托盘包装，我司进行正常烘烤作业。如客户提供的芯片包装不符行业标准，或对部分芯片有特殊要求需要烘烤导致的芯片不良，我司不承担物料损坏的责任。</li>
          </ul>
        </div>
        <template v-slot:footer>
          <div class="dialog-footer">
            <el-button class="sure" @click="pactClose">我已阅读并同意</el-button>
          </div>
      </template>
    </el-dialog>


    <!-- 收货地址 -->
    <addaddress
      v-if="address_modal"
      ref="addaddress"
      :form="address_form"
      @changeModal="changeAddress"
    ></addaddress>

    <!-- 发票信息 -->
    <addinvoice
      v-if="invoice_modal"
      ref="invoice"
      :info="invoiceInfo"
      :type="invoiceType"
      active="pay"
      @changeModal="changeInvoice"
    ></addinvoice>


  </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";

import addaddress from "@/components/AddAddress.vue";
import addinvoice from "@/components/AddInvoice.vue";
import { getData, postData } from "@/api/user";
import { reactive,toRefs,ref,nextTick } from "vue";
import dataCity from "@/assets/city";
import { oss } from "@/api/oss_upload";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter();
        const form_info = ref(null)
        const form_up = ref(null)
        const form_addr = ref(null)
        const form_invoice = ref(null)
        store.commit('setCount');

      var checkVeneer = (rule, value, callback) => {
          if(state.form.veneer_length == '' || state.form.veneer_width == '') {
            return callback(new Error("请输入PCB单板尺寸"));
          } else {
            callback();
          }
      };
      var checkFixTel = (rule, value, callback) => {
          if (!value) {
              return callback(new Error("请输入手机/固话"));
          }
          var mobile = /^1[345789]\d{9}$/,
              phone = /^0\d{2,3}-?\d{7,8}$/;
          if (mobile.test(value) || phone.test(value)) {
              callback();
          } else {
              return callback(new Error("请正确输入手机号/固话！"));
          }
      };

      var checkDuty = (rule, value, callback) => {
          if (!value) {
              return callback(new Error("请输入公司税号"));
          }
          const duty = /^[A-Za-z0-9]+$/;
          if (duty.test(value)) {
              callback();
          } else {
              return callback(new Error("公司税号税号只能是数字和字母！"));
          }
      };

      var checkTel = (rule, value, callback) => {
          if (!value) {
              return callback(new Error("请输入电话号码"));
          }
          var mobile = /^1[345789]\d{9}$/,
              phone = /^0\d{2,3}-?\d{7,8}$/;
          if (mobile.test(value) || phone.test(value)) {
              callback();
          } else {
              return callback(new Error("请正确输入电话号码！"));
          }
      };

      var checkEmail = (rule, value, callback) => {
          if (!value) {
              return callback(new Error("请输入邮箱地址"));
          }

          let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;

          if (reg.test(value)) {
              callback();
          } else {
              return callback(new Error("请输入正确的邮箱！"));
          }
      };



        let state = reactive({
            ali_oss: store.state.ali_oss,
            userid: store.state.userInfo.id,
            step: 1,
            // smt首页进来
            info:{},

            // 编辑
            id:0,
            pinfo:{},

            // 第一步
            form: {
                veneer_num: '',
                veneer_length:'',
                veneer_width:'',
                panel_num:'',

                smt_cover_type:'单面',
                smt_material_num:'',
                smt_welding_num:'',

                dip_plug_need:'否',
                dip_plug_material_num:'',
                dip_plug_welding_num:'',

                special_patch:'否',
                special_program:'否',
                special_element_num:'',
                special_veneer_num:'',

                function_test:'否',
                old_test:'否',

                // 报价
                smt_patch_money:0,
                dip_plug_money:0,
                special_technique_money:0,
                packaging_money:0,
                forecast_money_base:0, // 不含税
                forecast_money:0, // 含税
            },
            rules: {
              veneer_num: [{required: true,message: "请输入单板生产数量",trigger: "blur",},],
              veneer_length: [{ required: true, validator: checkVeneer, trigger: "blur" }],
              panel_num:[ { required: true, message: "请输入拼版数量", trigger: "blur", }, ],
              smt_welding_num:[{required: true,message: "请输入贴片焊盘数",trigger: "blur",},],
              dip_plug_welding_num:[{required: true,message: "请输入插件焊盘数",trigger: "blur",},],
            },

            // 第二步
            form_file:{
                pcb_custom:'平台代采',
                pcb_custom_file:'',
                pcb_custom_file_name: '',

                bom_custom:'平台代采',
                bom_custom_file:'',
                bom_custom_file_name:'',

                smt_file:'',
                smt_file_name:'',

                print_file:'',
                print_file_name:'',
                
                stencil:'平台定制',
                note:'',
                connect_person:'',
                connect_phone:'',
                connect_email:''
            },
            rules_file: {
              pcb_custom_file: [{required: true,message: "请上传PCB文件",trigger: "change",},],
              bom_custom_file: [{ required: true, message: "请上传BOM文件", trigger: "change" }],
              smt_file:[ { required: true, message: "请上传SMT文件", trigger: "change", }, ],
              print_file:[{required: true,message: "请上传丝印图文件",trigger: "change",},],
              connect_phone: [{ required: true, validator: checkTel, trigger: "change" }],
              connect_email: [{ required: true, validator: checkEmail, trigger: "change" }],
            },
            // pcb
            pcb_host: "", 
            pcb_data: {}, 
            pcb_list: [], 
            pcb_show: true,
            // bom 
            bom_host: "", 
            bom_data: {}, 
            bom_list: [], 
            bom_show: true,
            // smt
            smt_host: "", 
            smt_data: {}, 
            smt_list: [], 
            smt_show: true,
            // print
            print_host: "", 
            print_data: {}, 
            print_list: [], 
            print_show: true,


            
            // 第三步
            baseInfo:{},
            order_bol:false,


            // 地址
            options: dataCity,
            address_modal: false,
            address_form: {}, // 当前 收货地址
            address_rules: {
                name: [{ required: true, message: "请输入收货人", trigger: "blur" }],
                district: [{ required: true, message: "请选择所在地区", trigger: "blur" },],
                address: [{ required: true, message: "请输入详细地址", trigger: "blur" },],
                tel: [{ required: true, validator: checkFixTel, trigger: "blur" }],
            },
            address_show: "",
            active_index: 0, // 寄送的地址
            addressList: [],

            // 发票
            check_type:'不开票',
            invoice_modal: false,
            choose_invoice: false, // 是否选择开票
            has_invoice: true, // 有发票信息
            invoiceType: "",
            invoiceInfo: {
                company: "",
                tax_number: "",
                address: "",
                tel: "",
                company_bank: "",
                bank_account: "",
                email: "",
            },
            invoice_rules: {
                company: [{ required: true, message: "请输入公司名称", trigger: "change" },],
                tax_number: [{ required: true, validator: checkDuty, trigger: "change" },],
                tel: [{ required: true, validator: checkTel, trigger: "change" }],
                email: [{ required: true, validator: checkEmail, trigger: "change" }],
            },

            //贴片协议
            pact_show: false,
            check_deal: false

        })
        // ------------------公共------------------/

        let roundFixed = (num) => {
          var result = parseFloat(num);
          if (isNaN(result)) {
            alert('传递参数错误，请检查！');
            return false;
          }
          result = Math.round(num * 100) / 100;
          var s_x = result.toString();
          var pos_decimal = s_x.indexOf('.');
          if (pos_decimal < 0) {
            pos_decimal = s_x.length;
            s_x += '.';
          }
          while (s_x.length <= pos_decimal + 2) {
            s_x += '0';
          }
          return s_x;
        }

        let to_first = () => {
          state.step = 1
          window.scrollTo(100,0)
        }

        let to_second = () => {
          form_info.value.validate((valid) => {
            if(valid) {
              state.step = 2
              window.scrollTo(100,0)
            }
          });
        }
        
        let to_two = () => {
          state.step = 2
          window.scrollTo(100,0)
        }

        let to_third = () => {
          form_up.value.validate((valid) => {
            if(valid) {
              state.step = 3
              window.scrollTo(100,0)
            }
          });
        }


        // ------------------第一步------------------
        // 输入框改变
        let number = (value,type) => {
          if(value.length == 1 && value==0){
            state.form[type] = ''
          }else{
            state.form[type] = value.replace(/[^0-9]/g,'');
          }

          // 基础信息
          if(type == 'veneer_num') {
            change_package(true)
            change_smt(state.form.smt_cover_type,true)
            change_dip(state.form.dip_plug_need,true)
            change_patch(state.form.special_patch,true)
            change_program(state.form.special_program,true)
          }

          // SMT贴片
          if(type == 'smt_material_num' || type == 'smt_welding_num') {
            change_smt(state.form.smt_cover_type)
          }
           // DIP插件
          if(type == 'dip_plug_material_num' || type == 'dip_plug_welding_num') {
            change_dip(state.form.dip_plug_need)
          }
          // 特殊工艺
          if(type == 'special_element_num' || type == 'special_veneer_num') {
            change_patch(state.form.special_patch)
            change_program(state.form.special_program)
          }
        }

        let part = (value,type) => {
          let temp = value.toString();
          temp = temp.replace(/。/g, ".");
          temp = temp.replace(/[^\d.]/g, ""); 
          temp = temp.replace(/^\./g, ""); 
          temp = temp.replace(/\.{2,}/g, ""); 
          temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
          temp = temp.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); 
          state.form[type] = temp


          if(type == 'veneer_length' || type == 'veneer_width') {
            change_package(true)
            change_smt(state.form.smt_cover_type,true)
            change_dip(state.form.dip_plug_need,true)
            change_patch(state.form.special_patch,true)
            change_program(state.form.special_program,true)
          }
        }

        // SMT贴片
        let change_smt = (val,bol) => {
          if(bol) {
            validate()
          }

          let veneer_num = state.form.veneer_num || 0
          let material = state.form.smt_material_num || 0
          let welding = state.form.smt_welding_num || 0
          let tube =  welding *0.01 * veneer_num +material*18 
          
          if(veneer_num > 0) {
            let result = val == '单面' ? tube : (tube > 0 ? tube + 200 : tube)
            state.form.smt_patch_money = roundFixed(result)
          }
          change_total()
        }

        // DIP插件
        let change_dip = (val,bol) => {
          if(bol) {
            validate()
          }

          let veneer_num = state.form.veneer_num || 0
          let material = state.form.dip_plug_material_num || 0
          let welding = state.form.dip_plug_welding_num || 0
          let tube =  welding *0.1 * veneer_num +material*18 
          
          if(veneer_num > 0) {
            let result = val == '是' ? tube : 0
            state.form.dip_plug_money = roundFixed(result)
          }else {
            state.form.dip_plug_money = 0
          }

          if(val == '否') {
            state.form.dip_plug_material_num =   ''
            state.form.dip_plug_welding_num =   ''
          }
          change_total()
        }

        // 需要拆板
        let change_patch = (val,bol) => {
          if(bol) {
            validate()
          }

          let patch = val
          let program = state.form.special_program
          let veneer_num = state.form.veneer_num || 0
          let element = state.form.special_element_num || 0
          let special = state.form.special_veneer_num || 0
          let tube =  patch =='是' ? 40 + veneer_num*0.4+element*special*2 : 0
          let tube2 = program == '是' ? 2*veneer_num : 0
          
          if(veneer_num > 0) {
            let result =  (tube > 0 ? tube: 0) + tube2
            state.form.special_technique_money = roundFixed(result)
            
          }else {
            state.form.special_technique_money =  0
          }

          if(patch == '否' && program == '否') {
            state.form.special_element_num =   ''
            state.form.special_veneer_num =   ''
          }
          change_total()
        }

        // 烧录程序
        let change_program = (val,bol) => {
          if(bol) {
            validate()
          }
          let patch = state.form.special_patch
          let program = val
          let veneer_num = state.form.veneer_num || 0
          let element = state.form.special_element_num || 0
          let special = state.form.special_veneer_num || 0
          let tube =  patch =='是' ? 40 + veneer_num*0.4+element*special*2 : 0
          let tube2 = program == '是' ? 2*veneer_num : 0
          
          if(veneer_num > 0) {
            let result =  (tube > 0 ? tube: 0) + tube2
            state.form.special_technique_money =  roundFixed(result)
          }else {
            state.form.special_technique_money =  0
          }

          if(patch == '否' && program == '否') {
            state.form.special_element_num =   ''
            state.form.special_veneer_num =   ''
          }
          change_total()
        }

        let change_package = (bol) => {
          if(bol) {
            validate()
          }
          let veneer_num = state.form.veneer_num || 0
          let length = state.form.veneer_length || 0
          let width = state.form.veneer_width || 0
          let tube =  23+0.005*length*width*veneer_num

          if(veneer_num > 0 && length > 0 && width > 0) {
            let result =  tube > 0 ? tube : 0
            state.form.packaging_money =  roundFixed(result)
          }else {
            state.form.packaging_money = 0
          }
          change_total()
        }

        let change_total = () => {
          let result = Number(state.form.smt_patch_money) + Number(state.form.dip_plug_money) + Number(state.form.special_technique_money) + Number(state.form.packaging_money)
          state.form.forecast_money_base =  roundFixed(result) 
          state.form.forecast_money = roundFixed(Number(result) + Number(result*0.17))
        }

        let validate = () => {
          nextTick(()=> {
            // form_info.value.validateField('veneer_num')
            // form_info.value.validateField('veneer_length')
            // form_info.value.validateField('veneer_width')
          })
        }

        // ------------------首页进来from=smt,编辑进来from=user--------------
        let smt = () => {
          let info = route.query 
          state.id = info.id
          if(info.from =='smt') {
            info.smt_cover_type = info.smt_cover_type.slice(0,2)

            if(info) {
              state.form.smt_cover_type= info.smt_cover_type 
              state.form.veneer_num= info.veneer_num
              state.form.smt_material_num= info.smt_material_num
              state.form.smt_welding_num= info.smt_welding_num
              state.form.dip_plug_material_num= info.dip_plug_material_num
              state.form.dip_plug_welding_num= info.dip_plug_welding_num

              if( state.form.dip_plug_material_num > 0 || state.form.dip_plug_welding_num > 0) {
                state.form.dip_plug_need = '是'
              } 
            } 
            change_package(true)
            change_smt(state.form.smt_cover_type,true)
            change_dip(state.form.dip_plug_need,true)
            change_patch(state.form.special_patch,true)
            change_program(state.form.special_program,true)
          }else {
            getData({
              url: `api/smt/detail/${info.id}`,
              data:  {}
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                  let pinfo = data.data.order
                  state.pinfo = pinfo
                  state.form = {
                      veneer_num: pinfo.veneer_num,
                      veneer_length:pinfo.veneer_length,
                      veneer_width:pinfo.veneer_width,
                      panel_num:pinfo.panel_num,

                      smt_cover_type: pinfo.smt_cover_type,
                      smt_material_num:pinfo.smt_material_num,
                      smt_welding_num:pinfo.smt_welding_num,

                      dip_plug_need:pinfo.dip_plug_need,
                      dip_plug_material_num:pinfo.dip_plug_material_num,
                      dip_plug_welding_num:pinfo.dip_plug_welding_num,

                      special_patch: pinfo.special_patch,
                      special_program: pinfo.special_program,
                      special_element_num:pinfo.special_element_num,
                      special_veneer_num:pinfo.special_veneer_num,

                      function_test: pinfo.function_test,
                      old_test: pinfo.old_test,

                      // 报价
                      smt_patch_money:pinfo.smt_patch_money,
                      dip_plug_money:pinfo.dip_plug_money,
                      special_technique_money:pinfo.special_technique_money,
                      packaging_money:pinfo.packaging_money,
                      forecast_money:pinfo.forecast_money,
                      forecast_money_base:pinfo.forecast_money_base,
                  }
                  state.form_file ={
                      pcb_custom: pinfo.pcb_custom ==2 ? '平台代采':'自己提供PCB',
                      pcb_custom_file:pinfo.pcb_custom_file,
                      pcb_custom_file_name: pinfo.pcb_custom_file_name,

                      bom_custom:pinfo.bom_custom ==2 ? '平台代采':'自己提供物料',
                      bom_custom_file:pinfo.bom_custom_file,
                      bom_custom_file_name:pinfo.bom_custom_file_name,

                      smt_file:pinfo.smt_file,
                      smt_file_name:pinfo.smt_file_name,

                      print_file:pinfo.print_file,
                      print_file_name:pinfo.print_file_name,
                      
                      stencil:pinfo.stencil ==2 ? '平台定制':'客户提供',
                      note:pinfo.note,
                      connect_person:pinfo.connect_person,
                      connect_phone:pinfo.connect_phone,
                      connect_email:pinfo.connect_email
                  }
                  // pcb
                  state.pcb_list= [pinfo.pcb_custom_file]
                  state.pcb_show= state.pcb_list.length > 0
                  // bom 
                  state.bom_list= [pinfo.bom_custom_file]
                  state.bom_show= state.bom_list.length > 0
                  // smt
                  state.smt_list= [pinfo.smt_file]
                  state.smt_show= state.smt_list.length > 0
                  // print
                  state.print_list= [pinfo.print_file]
                  state.print_show= state.print_list.length > 0

                  //地址
                  state.check_type = pinfo.invoice_type
                }else {
                    ElMessage.error(data.data.msg);
                }
            });
            change_package(false)
            change_smt(state.form.smt_cover_type,false)
            change_dip(state.form.dip_plug_need,false)
            change_patch(state.form.special_patch,false)
            change_program(state.form.special_program,false)
          }
        }
        smt()
        
        // ------------------第二步------------------
        const url = "api/oss_direct/sign_key";
        // PCB
        let pcb_change = (val) => {
          state.form_file.pcb_custom = val
        }
        let pcb_before = (file) => {
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
            state.$message.error("上传文件大小不能超过 5MB!");
            return false;
          }
        
          return oss(file.name, url).then((res) => {
            state.pcb_host = res.host;
            state.pcb_data = res;
          });
        }
        let pcb_success = (res, file, fileList) => {
          state.pcb_list = fileList;
          if (res.code === 200) {
            state.pcb_show = false;
            
            state.form_file.pcb_custom_file_name = file.name
            state.form_file.pcb_custom_file = state.ali_oss + res.filename;
          } else {
            state.pcb_show = true;
            state.$warning({
              title: "上传失败",
              desc: "请重新上传",
            });
          }
        }
        let pcb_remove = (file, fileList) => {
          state.pcb_show = true;
          state.pcb_list = fileList;
          state.form_file.pcb_custom_file = "";
          state.form_file.pcb_custom_file_name = ""
        }

        // BOM
        let bom_before = (file) => {
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
            state.$message.error("上传文件大小不能超过 5MB!");
            return false;
          }

          return oss(file.name, url).then((res) => {
            state.bom_host = res.host;
            state.bom_data = res;
          });
        }
        let bom_success = (res, file, fileList) => {
          state.bom_list = fileList;
          if (res.code === 200) {
            state.bom_show = false;
            state.form_file.bom_custom_file_name = file.name
            state.form_file.bom_custom_file = state.ali_oss + res.filename;
          } else {
            state.bom_show = true;
            state.$warning({
              title: "上传失败",
              desc: "请重新上传",
            });
          }
        }
        let bom_remove = (file, fileList) => {
          state.bom_show = true;
          state.bom_list = fileList;
          state.form_file.bom_custom_file = "";
          state.form_file.bom_custom_file_name = ""
        }

        // SMT
        let smt_before = (file) => {
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
            state.$message.error("上传文件大小不能超过 5MB!");
            return false;
          }

          return oss(file.name, url).then((res) => {
            state.smt_host = res.host;
            state.smt_data = res;
          });
        }
        let smt_success = (res, file, fileList) => {
          state.smt_list = fileList;
          if (res.code === 200) {
            state.smt_show = false;
            state.form_file.smt_file_name = file.name
            state.form_file.smt_file = state.ali_oss + res.filename;
          } else {
            state.smt_show = true;
            state.$warning({
              title: "上传失败",
              desc: "请重新上传",
            });
          }
        }
        let smt_remove = (file, fileList) => {
          state.smt_show = true;
          state.smt_list = fileList;
          state.form_file.smt_file = "";
          state.form_file.smt_file_name = ""
        }

        // print
        let print_before = (file) => {
          const isLt = file.size / 1024 / 1024 < 5;
          if (!isLt) {
            state.$message.error("上传文件大小不能超过 5MB!");
            return false;
          }

          return oss(file.name, url).then((res) => {
            state.print_host = res.host;
            state.print_data = res;
          });
        }
        let print_success = (res, file, fileList) => {
          state.print_list = fileList;
          if (res.code === 200) {
            state.print_show = false;
            state.form_file.print_file_name = file.name
            state.form_file.print_file = state.ali_oss + res.filename;
          } else {
            state.print_show = true;
            state.$warning({
              title: "上传失败",
              desc: "请重新上传",
            });
          }
        }
        let print_remove = (file, fileList) => {
          state.print_show = true;
          state.print_list = fileList;
          state.form_file.print_file = "";
          state.form_file.print_file_name = ""
        }
        
        // ------------------第三步------------------

        let get_info = () => {
            getData({
              url: "api/smt/order_base",
              data:  {}
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let info = data.data;
                    if (info.invoiceInfo) {
                        state.invoiceInfo = info.invoiceInfo;
                        state.has_invoice = true;
                    } else {
                        state.has_invoice = false;
                    }
                    state.addressList = info.address;
                    state.address_show = info.address.length > 0 ? true : false;
                    state.baseInfo = info.shopping_car_base;
                  

                    // 编辑进来
                    if(state.id) {
                      state.addressList.forEach((v,k)=>{
                        if(v.id == state.pinfo.address_id) {
                          state.active_index = k
                        }
                      })
                    }
      

                }else {
                  ElMessage.error(data.data.msg);
                }
            });
        }
        get_info()

        // 选择地区
        let cascaderChange = (value) => {
            state.address_form.area = `${value[0]}/${value[1]}/${value[2]}`;
        }

        // 保存地址
        let sub_address_form = () => {
            form_addr.value.validate((valid) => {
                if (valid) {
                    let url = `api/my_home/my_address`;
                    let params = {
                        name: state.address_form.name,
                        district: state.address_form.area,
                        address: state.address_form.address,
                        tel: state.address_form.tel,
                        postcode: state.address_form.postcode,
                        default: "是",
                    };

                    postData({
                        url: url,
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            changeAddress(false)
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        }

        // 新增地址弹窗
        let changeAddress = (v) => {
            state.address_modal = v;
            if (!v) {
                get_info();
            }
        }
        
        // 选择寄送地址
        let toggle_address = (index) => {
            state.active_index = index;
        }

        // 选择发票类型
        let change_type = (v) => {
            state.check_type = v
        }

        let changeInvoice = (v, type) => {
            state.invoice_modal = v;
            state.invoiceType = type;

            if (!v) {
                get_info();
            }
        }

        let sub_invoice_form = () => {
            form_invoice.value.validate((valid) => {
                if (valid) {
                    let url = `api/invoices`;
                    let params = {
                        company: state.invoiceInfo.company,
                        tax_number: state.invoiceInfo.tax_number,
                        address: state.invoiceInfo.address,
                        tel: state.invoiceInfo.tel,
                        deposit_bank: state.invoiceInfo.deposit_bank,
                        bank_account: state.invoiceInfo.bank_account,
                        email: state.invoiceInfo.email
                    };
                    postData({
                        url: url,
                        data: params,
                    }).then((res) => {
                        let data = res.data;
                        if (data.code === 200) {
                            ElMessage.success(data.data.msg);
                            get_info()
                        } else if (data.code === 422) {
                            ElMessage.error(data.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        }

        // 提交
        let order = () => {
            if(state.check_deal) {
            
              if (!state.has_invoice && state.check_type !='不开票') {
                  ElMessage.error("请填写发票信息");
                  return;
              }

              if (state.addressList.length <= 0) {
                  ElMessage.error("请填写收货地址");
                  return;
              }

              let params = {
                veneer_num: state.form.veneer_num,
                veneer_length:state.form.veneer_length,
                veneer_width:state.form.veneer_width,
                panel_num:state.form.panel_num,

                smt_cover_type:state.form.smt_cover_type,
                smt_material_num:state.form.smt_material_num,
                smt_welding_num:state.form.smt_welding_num,

                dip_plug_need:state.form.dip_plug_need,
                dip_plug_material_num:state.form.dip_plug_material_num,
                dip_plug_welding_num:state.form.dip_plug_welding_num,

                special_patch:state.form.special_patch,
                special_program:state.form.special_program,
                special_element_num:state.form.special_element_num,
                special_veneer_num:state.form.special_veneer_num,

                function_test:state.form.function_test,
                old_test:state.form.old_test,

        
                smt_patch_money:state.form.smt_patch_money,
                dip_plug_money:state.form.dip_plug_money,
                special_technique_money:state.form.special_technique_money,
                packaging_money:state.form.packaging_money,
                forecast_money_base:state.form.forecast_money_base,
                forecast_money:state.form.forecast_money,

                pcb_custom:state.form_file.pcb_custom =='平台代采'?2:1,
                pcb_custom_file:state.form_file.pcb_custom_file,
                pcb_custom_file_name: state.form_file.pcb_custom_file_name,

                bom_custom:state.form_file.bom_custom =='平台代采'?2:1,
                bom_custom_file:state.form_file.bom_custom_file,
                bom_custom_file_name:state.form_file.bom_custom_file_name,

                smt_file:state.form_file.smt_file,
                smt_file_name:state.form_file.smt_file_name,

                print_file:state.form_file.print_file,
                print_file_name:state.form_file.print_file_name,
                
                stencil:state.form_file.stencil =='平台定制'?2:1,
                note:state.form_file.note,
                connect_person:state.form_file.connect_person,
                connect_phone:state.form_file.connect_phone,
                connect_email:state.form_file.connect_email,

                address_id: state.addressList[state.active_index].id,
                need_invoice: state.check_type == '不开票' ? 0 : 1,
                invoice_type: state.check_type,
                invoice_id: state.check_type == '不开票' ? 0 : state.invoiceInfo.id,
              }
              state.order_bol = true
              if(state.id) {
                postData({
                  url: `api/smt/update/${state.id}`,
                  data: params,
                }).then((res) => {
                  let data = res.data;
                  if (data.code === 200) {
                    ElMessage.success(data.data.msg)
                    setTimeout(() => {
                      router.push({ path: "/user", query: { temp: "SmtOrder", id: 5 }, });
                      window.scrollTo(100,0)
                    }, 2000);
                  } else {
                    ElMessage.error(data.data.msg);
                  }
                  
                });

              }else {
                postData({
                  url: "api/smt/add",
                  data: params,
                }).then((res) => {
                  let data = res.data;
                  if (data.code === 200) {
                    ElMessage.success(data.data.msg)
                    setTimeout(() => {
                      router.push({ path: "/user", query: { temp: "SmtOrder", id: 5 }, });
                      window.scrollTo(100,0)
                    }, 2000);
                  } else {
                    ElMessage.error(data.data.msg);
                  }
                });
              }
          }else {
            ElMessage.error('请阅读并同意《贴片协议》');
          }
        }

        // 贴片协议
        let pactOpen = () => {
          state.pact_show = true
        }

        let pactClose = () => {
          state.pact_show = false
          state.check_deal = true
        }

        let change_deal = (bol) => {
            state.check_deal = bol
        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query: {
                    id: v.goods_id
                }
            })
            window.scrollTo(100,0)
        }

        return {
          form_info,
          form_up,
          ...toRefs(state),
          number,
          part,
          to_first,
          to_second,
          to_third,
          change_smt,
          change_dip,
          change_patch,
          change_program,
          change_package,
          change_total,
          validate,
          roundFixed,
          get_info,
          changeAddress,
          sub_address_form,
          toggle_address,
          changeInvoice,
          sub_invoice_form,
          cascaderChange,
          order,
          form_invoice,
          form_addr,
          change_type,
          to_detail,
          pcb_change,
          pcb_before,
          pcb_success,
          pcb_remove,
          bom_before,
          bom_success,
          bom_remove,
          smt_before,
          smt_success,
          smt_remove,
          print_before,
          print_success,
          print_remove,
          smt,
          pactOpen,
          pactClose,
          change_deal,
          to_two
        }
    },
    components: {
        topnav,
        topmenu,
        foot,
        addaddress,
        addinvoice,
        toolbar
    },

};
</script>

<style lang="less" >
input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    width: 17px!important;
    height: 17px!important;
    font-size: 14px;
    border-radius: 4px;
}

input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    color: #fff;
    background-color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    visibility: visible;
    text-align: center;
    content: " ";
    border: 1px solid #d1d1d1;
}

input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 12px;
    font-weight: bold;
    background-color: #1C46B7;
}
.smt_flow {
  padding: 154px 0 440px;
  
  .step_box {
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/s-bzt0bg.png) no-repeat center center;
    background-size: 100% 100%;
    margin: 10px 0 15px;
    padding: 0 116px;
    .line {
      width: 117px;
      height: 2px;
      background: #E6E6E6;
    }
    .line.active {
      background: #3385F6;
    }
    .step {
      display:flex;
      align-items: center;
      justify-content: center;
      .step_num {
        display: inline-block;
        width: 39px;
        height: 39px;
        background: #D0D0D0;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 600;
        line-height: 39px;
        color: #FFFFFF;
        text-align:center;
        margin-right: 10px;
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #999;
        margin-bottom: 5px;
      }
      .txt {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #999;
      }
    }
    .step.active {
      .step_num {
        background: #3385F6;
      }
      .title {
        color: #333333;
      }
      .txt {
        color: #666666;
      }
    
    
    }

  }
  .flow_left {
    flex: 1;
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
    padding: 43px 48px 25px;
    .log_title {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #666666;
      padding-left: 15px;
      margin-bottom: 15px;
      .down {
        width: 172px;
        height: 24px;
        background: #FFF7DE;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #FF9300;
        padding: 3px 8px;
        margin-left: 110px;
      }
    }
    .log_box {
      border-bottom: 1px solid #F0F0F0;
      margin-bottom: 24px;
      padding-bottom: 10px;
      .symbol {
        margin: 0 10px;
      }
      .el-form-item {
        margin-bottom: 8px;
      }
      .el-input {
        width: 243px;
        .el-input-group__append {
          height: 30px;
          width: 38px;
          padding: 0;
          text-align: center;
          border: 1px solid #E8E8E8;
          background: #f5f5f5;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          color: #666666;
        }
      }
      .el-input__inner,.el-form-item__content,.el-form-item__label{
        height: 30px;
        line-height: 30px;
      }
      .el-form-item__label{
        text-align: left;
        position: relative;
      }
      .el-form-item__label:before {
        position:absolute;
        top:50%;
        left:-10px;
        transform: translateY(-50%);
      }
      .el-input.short{
        width: 108px;
      }
      .el-form-item__content {
        position: relative;
      }
      .el-form-item__error {
        position: absolute;
        top: 0;
        left: 253px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        width: 300px;
      }
      
    }
  }
  .flow_right {
    width: 550px;
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
    padding: 40px 40px 0; 
    margin-left: 20px;
    .process_title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #333333;
      margin-bottom: 12px;
    }

    .process_table {
      border:1px solid #e8e8e8; 
      border-collapse:collapse; 
      table-layout: fixed;
      width: 100%;
      font-size: 13px;
      color: #333;
      font-weight: 400;
      margin-bottom: 28px;
      td {
        height: 36px;
        border:1px solid #E8E8E8; 
        text-align: center;
      }
      td.bg {
        width: 106px;
        background: #f5f5f5;
      }
      .orange {
        color: #FF7F00;
      }
      .express {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #666666;
        padding: 0 12px;
      }
    }

    .process_price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 23px 17px;
      background: #F0F7FF;
      margin-bottom: 15px;
      .price_title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
      }
      .price_money {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        color: #FF7F00;
      }
    }

    .process_tips {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #999999;
      margin-bottom: 55px;
    }

    .process_btn {
      width: 100%;
      height: 50px;
      background: #316DFA;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      color: #FFFFFF;
      text-align:center;
      padding: 0;
    }
  }

  /* 第二步 */
  .flow_file {
    background: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.04);
    width: 100%;
    padding: 30px 210px 100px;
    .file_title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #333333;
      margin-bottom: 20px;
    }

    .el-form-item {
      margin-bottom: 50px;
    }

    .el-form-item__label{
      text-align: left;
      position: relative;
      height: 30px;
      line-height: 30px;
    }
    .el-form-item__label:before {
      position:absolute;
      top:50%;
      left:-10px;
      transform: translateY(-50%);
    }
    .el-form-item__content {
      display:flex;
      flex-direction: column;
      line-height: 30px;
    }
    .el-textarea__inner {
      min-height: 140px!important;
    }
    .el-upload {
      text-align:left;
    }

    .up_box {
      margin-top: 16px;
      .up_btn {
        width: 125px;
        height: 30px;
        background: rgba(245, 245, 245, 0.39);
        border: 1px solid #E8E8E8;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
        color: #666666;
        text-align:center;
        .iconfont {
          font-size: 12px;
          margin-right: 5px;
        }
      }
      .up_file {
        display:flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #333333;
        .file-icon {
          margin-right: 5px;
          height: 17.67px;
          width: 18.28px;
        }
        .close-icon {
          margin-left: 10px;
          color:#666;
          height: 12px;
          width: 12px;
          cursor: pointer;
        }
      }

      .bom-btn:hover {
        color: #4d90fb;
        background: #f4f8ff;
      }
    }
    .up_box_none {
      margin-top: 0;
    }

    .up_tip{
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #999999;
      margin-top: 9px;
    }

    .tel {
      display:flex;

      .el-form-item {
        margin-bottom:0;
        margin-right: 30px;
      }
      .el-form-item__label{
        width: 70px!important;
      }
      .el-input__inner {
        width: 180px!important;
      }

      .el-input {
        width: 180px;
      }
      .el-input__inner,.el-form-item__content,.el-form-item__label{
        height: 30px;
        line-height: 30px;
      }
    }

    .file_btn {
      margin-top: 5px;
      margin-left: 120px;
      .btn {
        width: 180px;
        height: 50px;
        background: #fff;
        border: 1px solid #316DFA;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #316DFA;
        text-align: center;
        padding: 0;
      }
      .btn.next {
        background: #316DFA;
        color: #fff;
        margin-left: 20px;
      }
    }

  }


  /* 第三步*/
  .flow_pay {
    .pay_title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: #333;
        margin-bottom: 20px;
    }

    .settlement_main {
        .com_box {
            margin-bottom: 20px;
            

            .com_title {
                position: relative;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                background: #ffffff;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                color: #333333;
                padding: 0 17px;
                border: 1px solid #ddd;
                top:1px;
                border-bottom: 1px solid #fff;
            }
        }
        /*没地址 */
        .box_main {
            border: 1px solid #ddd;
            padding: 40px 24px 33px;
            background: #fff;

            .el-form {
                .el-form-item {
                .el-input,
                .el-cascader {
                    width: 234px;
                }
                }
            }
        }

        /* 有地址 */
        .has_address_box {
            cursor: pointer;

            .item_address {
                margin-bottom: 13px;
                padding: 5px 16px;
                display: flex;
                align-items: center;
                border: 1px solid transparent;

                .left_txt {
                    margin-right: 22px;
                    font-size: 14px;
                    color: #fff;
                    visibility: hidden;
                    flex-shrink: 0;
                }

                .readio_box {
                    position: relative;
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: #fff;
                    border: 1px solid #a9a9a9;
                    overflow: hidden;
                    flex-shrink: 0;

                    .com_radio {
                        width: 100%;
                        height: 100%;
                    }

                    .active_radio {
                        display: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #fff;
                    }
                }

                .address_box {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                }

                .default_address {
                    margin-left: 13px;
                    font-size: 14px;
                    color: #013EAA;
                    flex-shrink: 0;
                    margin-left: auto;
                }
            }

            /* 选中的地址 */
            .active_address {
                border: 1px solid #013EAA;
                background: #f5faff;

                .left_txt {
                    visibility: initial;
                    color: #013EAA;
                }

                .readio_box {
                    background: #013EAA;
                    border: 1px solid #013EAA;

                    .active_radio {
                    display: block;
                    }
                }
            }

            /* 新增收货地址 */
            .add_address_btn {
                display: inline-block;
                margin-top: 10px;
                margin-left: 80px;
                padding: 0 14px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #013EAA;
                background: #ffffff;
                border: 1px solid #013EAA;
                border-radius: 3px;
                cursor: pointer;
            }
        }

        .el-radio {
            height: auto;
        }

        .el-radio__input.is-checked .el-radio__inner {
                background: #013EAA;
                border: 1px solid #013EAA;
        }

        .el-radio__input.is-checked+.el-radio__label {
            color: #013EAA;
        }


        .choice {
            .choice_title {
                line-height: 40px;
                margin-right: 20px;
            }
        }

        /* 增值税 */
        .invoice_box {
            .box_main {
                padding: 40px 24px 33px;

                
                .warm_prompt {
                    margin: 20px 0;
                    font-size: 14px;
                    font-weight: 600;
                    color: #ffb34b;
                }



                .check_detail {
                    background: #F5FAFF;
                    padding: 18px 22px;
                }

                .confirm_btns {
                    .el-button {
                    padding: 0;
                    width: 68px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    text-align: center;
                    border: 1px solid #eaeaea;
                    border-radius: 3px;
                    color: #fff;
                    background: #013EAA;
                    }
                }

                /* 有发票 */
                .has_invoice {
                    .invoice_form_view {
                    display: flex;
                    align-items: center;

                    .col-form-label {
                        padding: 0;
                        width: 90px;
                        line-height: 32px;
                        font-size: 13px;
                        font-weight: 400;
                        color: #666;
                        flex-shrink: 0;
                    }

                    p {
                        font-size: 14px;
                        color: #333333;
                    }
                    }

                    .edit_btns {
                    margin-top: 20px;

                    .item_btn {
                        padding: 0 15px;
                        font-size: 13px;
                        color: #013EAA;
                        height: 14px;
                        line-height: 1;
                        cursor: pointer;
                    }

                    .left_btn {
                        padding-left: 0;
                        border-right: 1px solid #707070;
                    }
                    }
                }
            }

            
        }
    }

    .pay_table {
        margin-bottom: 20px;
        border: 1px solid #eaeaea;

        .payMain_hd {
            width: 100%;
            height: 32px;
            line-height: 32px;
            background: #f5f5f5;
            li {
                font-size: 12px;
                color: #666666!important;
                white-space: nowrap;
            }

            .order_lists {
                padding: 0;
            }

            .payTop {
                height: 32px;
                .list_chk {
                    display: inline-block;
                }
            }
        }

        .order_lists {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 20px 0 15px 0;
            border-bottom: 1px solid #eaeaea;
            overflow: hidden;

            .list_chk {
                width: 100px;
                display: inline-block;
            }

            li {
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                color: #333;
                line-height: 18px;
                
            }

            /* 图片 */
            .list_con {
                cursor:pointer;
                align-items: center;
                width: 125px;
                text-align: center;

                img {
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    border: 1px solid #eaeaea;
                    object-fit: contain;
                    flex-shrink: 0;
                }
            }

                /* 类型 */
            .list_type {
                width: 125px;
            }

            /* 产品名称*/
            .list_name {
                cursor:pointer;
                width: 180px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }

            .list_name:hover {
              color:#3385F6;
            }

            .list_model {
                width: 80px;
            }

            /*封装*/
            .list_pack {
                width: 150px;
            }

            /* 参数描述 */
            .list_info {
                width: 220px;
                justify-content: flex-start; 
                cursor: pointer;
            }

            /* 单价 */
            .list_price {
                width: 160px;
                justify-content: flex-start;
            }

            /* 库存 */
            .list_num {
                width: 150px;
            }

            .list_total {
                width: 150px;
                .sum_price {
                    font-size: 13px;
                    color: #FF7F23;
                    font-weight: bold;
                    line-height: 1;
                }
            }

        }

        .order_lists:last-child {
            border-bottom: none;
        }

        
    }

    .bottom_money {
        padding: 30px 30px 20px;
        background: #fff;
        border: 1px solid #DDDDDD;
        text-align: right;

        .info_box {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 13px;
            color: #666666;
            line-height: 1;
            .right_money {
                min-width: 160px;
                font-size: 14px;
                font-weight: 600;
                line-height: 28px;
                color: #FF7F23;
            }
        }

        .deal_box {
          margin-top: 20px;
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          .deal {
             display: flex;
              align-items: center;
              margin-right: 10px;
          }
          
          .blue {
            font-size: 13px;
            color: #3385F6;
            text-decoration: underline;
            cursor:pointer;
          }
        }

        .delivery_txt {
            margin: 14px 0 0;
            font-size: 13px;
            color: #333333;
            line-height: 1;
        }
        .check_tip {
          margin-top:19px;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          color: #999999;
        }
    }

    .pay_btn {
        text-align: right;
        margin: 32px auto 110px;

        .btn {
            width: 180px;
            height: 50px;
            background: #316DFA;
            border: 1px solid #316DFA;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 50px;
            color: #316DFA;
            font-size: 16px;
            font-weight: 600;
            line-height: 50px;
            color: #fff;
            padding: 0;
        }

        // .btn_sty {
        //     background: #b0b0b0;
        //     border:1px solid #b0b0b0;
        // }

        .btn-prev {
          background: #ffffff;
          color: #316DFA;
          border: 1px solid #316DFA;
        }
    }
  }

  // 获取加工信息
  .base_title {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #333333;
    margin-bottom: 9px;
  }
  .base_table {
    border:1px solid #e8e8e8; 
    border-collapse:collapse; 
    table-layout: fixed;
    width: 100%;
    font-size: 13px;
    color: #333;
    font-weight: 400;
    margin-bottom: 15px;
    a {
      color: #316DFA;
      text-decoration: underline!important;
    }
    td {
      // height: 36px;
      border:1px solid #E8E8E8; 
      padding: 8px;
      .title {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #999999;
      }
      .val {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #333333;
        float:right;
      }
      .num {
        width: 218px;
        padding-right: 15px;
      }
      .line {
        margin-bottom: 10px;
      }
      .line:last-child {
        margin-bottom: 0px;
      }
    }
    td.bg {
      width: 106px;
      background: #f5f5f5;
      text-align: center;
    }
    .orange {
      color: #FF7F00;
    }
    .express {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #666666;
      padding: 0 12px;
    }
    
  }
  .base_left {
    flex:1;
  }
  .base_right {
    margin-left: 24px;
    width: 470px;

    .day {
      height: 54px;
      background: #F5FAFF;
      font-size: 13px;
      font-weight: 600;
      line-height: 54px;
      color: #316DFA;
      padding: 0 20px;
    }
  }

  .base_tip {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #999999;
    margin-bottom: 55px;
  }

  /*单选 */
  .el-radio-group  {
    float:left;
  }
  .el-radio-button {
    margin-right: 12px;
    border-radius: 3px;
    .el-radio-button__inner {
      padding: 0 10px;
      width: auto;
      height: 30px;
      background: rgba(255, 255, 255, 0.39);
      border: 1px solid #E8E8E8;
      font-size: 13px;
      font-weight: 400;
      line-height: 30px;
      color: #333333;
      text-align:center;
      box-shadow: none;
      transition:none;
    }
  }
  
  .el-radio-button.is-active {
    .el-radio-button__inner {
      border: 1px solid #316DFA;
      background:url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/30/%E5%9B%BE%E7%89%87/icon-jg.png) no-repeat bottom right;
    }
  }

}

</style>


<style lang="less">
.modal_pact {
    width: 645px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body {
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;
    }

    .dialog-footer {
      .el-button {
          width: auto;
          height: 32px;
          border: 1px solid #ddd;
          border-radius: 3px;
          font-size: 14px;
          line-height: 32px;
          color: #666666;
          padding: 0;
          background: #FAFAFA;
          margin-top: 20px;
          padding: 0 5px;

      }
      .sure {
          background: #1C46B7;
          color: #fff;
          border: 1px solid #1C46B7;
      }
    }

    .dialog-content {
      font-size: 13px;
      font-weight: 400;
      line-height: 26px;
      color: #333333;
      .space {
        margin: 10px 0 5px;
      }
    }
}
</style>
